



































































































































































import { Component, Vue } from "vue-property-decorator";
import { getCSRFToken, extractErrorMessage } from "../../services/actions";
import { rules } from "../../util/validation";
import axios, { AxiosError, AxiosResponse } from "axios";
import get from "lodash/get";
import flatten from "lodash/flatten";
import { scrollBehavior } from "../../util/dom";
import has from "lodash/has";

import {
  MAX_EMAIL_ADDRESS_LENGTH,
  MIN_EMAIL_ADDRESS_LENGTH,
  MAX_PASSWORD_LENGTH
} from "../../services/config/constants";

enum Status {
  IDLE = "idle",
  SUBMITTING = "submitting",
  SUCCESS = "success"
}

@Component
export default class RegistrationForm extends Vue {
  status: Status = Status.IDLE;
  loading: boolean = false;
  email: string = "";
  temporarypassword: string = "";
  password: string = "";
  confirmpassword: string = "";
  csrfToken: string = "";
  errors: string[] = [];
  formerrors: Record<string, string[]> = {};
  isopen: boolean = true;
  specialcharacters: string = "(! @ # $ % ^ & * ( ) _ + = - ~ . / ? > < , .)";

  mounted() {
    // this.getCSRFToken();
    this.reset();
  }

  get constants() {
    return {
      MAX_EMAIL_ADDRESS_LENGTH,
      MIN_EMAIL_ADDRESS_LENGTH,
      MAX_PASSWORD_LENGTH
    };
  }

  onformupdate($evt: Event) {
    const { target: $target } = $evt;
    if ($target instanceof HTMLInputElement) {
      this.validation();
    }
  }

  getfielderrors(fieldname: string) {
    if (has(this.formerrors, fieldname)) {
      const errors = get(this.formerrors, fieldname, []) as string[];
      return errors.length ? errors[0] : "";
    }

    return "";
  }

  validation() {
    this.formerrors = [
      "email",
      "temporarypassword",
      "confirmpassword",
      "password"
    ].reduce((errorbag: Record<string, string[]>, field: string) => {
      switch (field) {
        case "email": {
          const isvalid = rules.string.email(get(this.$data, field));
          return isvalid
            ? errorbag
            : { ...errorbag, email: ["Valid email address is required"] };
        }

        case "temporarypassword": {
          const isvalid = rules.string.password(get(this.$data, field));
          return isvalid
            ? errorbag
            : {
                ...errorbag,
                temporarypassword: ["Valid temporary password is required"]
              };
        }

        case "password": {
          const isvalid = rules.string.password(get(this.$data, field));
          return isvalid
            ? errorbag
            : { ...errorbag, password: ["Valid new password is required"] };
        }

        case "confirmpassword": {
          if (!rules.string.password(get(this.$data, "password"))) {
            return errorbag;
          }

          const isvalid = rules.string.equals(
            get(this.$data, field),
            get(this.$data, "password")
          );

          return isvalid
            ? errorbag
            : { ...errorbag, confirmpassword: ["Passwords must match"] };
        }

        default: {
          return errorbag;
        }
      }
    }, {} as Record<string, string[]>);
  }

  submit() {
    this.errors = [];
    this.status = Status.SUBMITTING;
    this.loading = true;

    const {
      email,
      temporarypassword: temporaryPassword,
      confirmpassword: confirmPassword,
      password,
      csrfToken
    } = this.$data;

    this.validation();

    if (Object.keys(this.formerrors).length) {
      this.errors = flatten(Object.values(this.formerrors));
      this.status = Status.IDLE;
      this.loading = false;
      scrollBehavior("#user-registration-errors");
      return;
    }

    axios({
      url: "/api/v1/users/registration",
      method: "POST",
      data: { email, temporaryPassword, password, _csrf: csrfToken }
    })
      .then((response: AxiosResponse<string>) => {
        return this.getCSRFToken();
      })
      .then(() => {
        this.status = Status.SUCCESS;
        this.loading = false;
      })
      .catch((err: AxiosError) => {
        this.status = Status.IDLE;
        this.loading = false;
        this.errors = [extractErrorMessage(err)];
        scrollBehavior("#user-registration-errors");
        this.getCSRFToken();
        console.error("registration error", err);
      });
  }

  exit() {
    axios({
      url: `/api/v1/users/authentication`,
      method: "DELETE",
      headers: {
        "X-CSRF-Token": this.csrfToken
      }
    })
      .then((response: AxiosResponse) => {
        this.status = Status.SUCCESS;
        this.loading = false;
        window.location.assign("/login");
      })
      .catch((err: AxiosError) => {
        this.status = Status.IDLE;
        this.loading = false;
        this.errors = [extractErrorMessage(err)];
        this.getCSRFToken();
        scrollBehavior("#user-registration-errors");
        console.debug("[logout request] Error", err);
      });
  }

  reset() {
    this.email = "";
    this.temporarypassword = "";
    this.password = "";
    this.confirmpassword = "";
    this.csrfToken = "";
    this.status = Status.IDLE;
    this.loading = false;
    this.errors = [];
    scrollBehavior("#user-registration");
    this.getCSRFToken();
    const $form = document.getElementById(
      "user-registration"
    ) as HTMLFormElement;

    if ($form) {
      $form.reset();
      $form.noValidate = true;
      $form.noValidate = false;
    }
  }

  beforeDestroy() {
    this.reset();
  }

  getCSRFToken() {
    getCSRFToken()
      .then(token => (this.csrfToken = token))
      .catch(err => console.error("Failure to get csrf token", err));
  }
}
